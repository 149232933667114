<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.senders.overview')">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-lg-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{$t('common.Senders')}}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getSenders()">{{ $t('common.show_inactive') }}</b-switch>
              <div v-if="checkPermission('connect.senders.add')" class="ml-2 mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="mr-0 link" color="primary" @click="openSidebarRight('sender_details', { deliver_sender_id_external:  null });">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('common.Add_sender') }}</span>
                </CButton>
              </div>             
            </CCol>
          </CRow>          
        </CCardHeader>
        <CCardBody class="pt-0 pb-0">
          <CRow>
            <CCol cols="12" lg="12" class="p-0">
              <b-table class="data_table" ref="senderTable" :data="senders" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                <template slot-scope="props">
                  <b-table-column field="image" width="2.5%">
                    <div v-if="props.row.profile_image_id"
                         class="item_image d-inline-block align-middle"
                         v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/connect-deliver-sender/' + props.row.deliver_sender_id_external + '/' + props.row.profile_image_id + '.jpg' + '/' + clientToken }"
                         @click="checkPermission('connect.senders.edit') ? openSidebarRight('sender_details', { deliver_sender_id_external:  props.row.deliver_sender_id_external }) : null">
                    </div>
                    <div v-else
                         class="item_icon d-inline-block align-middle" 
                         @click="checkPermission('connect.senders.edit') ? openSidebarRight('sender_details', { deliver_sender_id_external:  props.row.deliver_sender_id_external }) : null">
                      <i class="fas fa-user"/>               
                    </div>                     
                  </b-table-column>
                  <b-table-column field="name" :label="$t('common.Name')" :searchable="searchEnabled" width="30%">
                    <span>{{props.row.name}}</span>
                  </b-table-column>
                  <b-table-column field="name" :label="$t('connect.Email_reply_to')" :searchable="searchEnabled" width="30%">
                    <span>{{props.row.email_reply_to}}</span>
                  </b-table-column>
                  <!-- <b-table-column field="updated" :label="$t('common.Last_update')">
                    <span>{{props.row.updated | moment("YYYY-MM-DD HH:mm")}}</span>
                  </b-table-column> -->
                  <b-table-column field="actions" :visible="checkPermission('connect.senders.edit')">
                    <div class="d-flex justify-content-lg-end">
                      <!-- <b-switch class="mb-0 mr-2" v-model="props.row.is_active" size="is-small" @input="updateSender(props.row.deliver_sender_id_external, props.row);">{{ $t('is_active') }}</b-switch> -->
                      <CButton class="m-0 d-inline-block" color="primary" @click="openSidebarRight('sender_details', { deliver_sender_id_external:  props.row.deliver_sender_id_external })">
                        <i class="fas fa-pen"/>
                      </CButton>
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="null"/>
                    <span v-else>{{ $t('connect.No_senders_found') }}</span>
                  </div>                
                </template>                              
              </b-table>
            </CCol>
          </CRow>          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Senders',
  components: {
    loadingSpinner,
    noPermission
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      apiBaseUrl: null,
      clientToken: null,     
      senders: [],
      overviewDataLoaded: false,
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,
      showInactive: false,
    }
  },
  methods: {
    getSenders () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/senders/overview')
      .then(res => {      
        this.senders = res.data.data;
        this.overviewDataLoaded = true;

        if(!this.showInactive) {
          this.senders = this.senders.filter( i => ['Y'].includes( i.active ) );
        }

        (this.senders.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateSender(deliverSenderIdExternal, data) {
      let params = {}
      params.senderData = data;
      (params.senderData.is_active === true) ? params.senderData.active = 'Y' : params.senderData.active = 'N';
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/deliver/sender/' + deliverSenderIdExternal, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('common.Sender_updated'), type: 'is-success', duration: 2000 });
        // Update the sender details
        this.getSenderDetails(deliverSenderIdExternal);        
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })         
    },
    getSenderDetails(deliverSenderIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/sender/' + deliverSenderIdExternal)
      .then(res => {
        let updatedSender = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.senderTable !== undefined ) {
          // Get the sender index
          let senderIndex = this.$refs.senderTable.data.findIndex(x => x.deliver_sender_id_external == updatedSender.deliver_sender_id_external);
          // Update the is active value for the row
          this.$refs.senderTable.data[senderIndex].name = updatedSender.name;
          this.$refs.senderTable.data[senderIndex].updated = updatedSender.updated;
          this.$refs.senderTable.data[senderIndex].is_active = updatedSender.is_active;
          // Hide (newly) inactive senders  
          if(!this.showInactive) this.senders = this.senders.filter( i => ['Y'].includes( i.active ));
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token'); 

    this.getPlatformPermissions();
    this.getSenders();
  
    this.$bus.$on('update_senders', () => {
      this.getSenders();
    });

    this.$bus.$on('update_sender_details', (deliverSenderIdExternal) => {
      this.getSenderDetails(deliverSenderIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_senders');
  } 

}
</script>